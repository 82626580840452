export const commonApiURL: string = process.env.NEXT_PUBLIC_COMMON_API_URL
  ? process.env.NEXT_PUBLIC_COMMON_API_URL
  : '${NEXT_PUBLIC_COMMON_API_URL}';

export const frApiURL: string = process.env.NEXT_PUBLIC_FR_API_URL
  ? process.env.NEXT_PUBLIC_FR_API_URL
  : '${NEXT_PUBLIC_FR_API_URL}';

export const ukApiURL: string = process.env.NEXT_PUBLIC_UK_API_URL
  ? process.env.NEXT_PUBLIC_UK_API_URL
  : '${NEXT_PUBLIC_UK_API_URL}';

export const ukSearchApiURL: string = process.env.NEXT_PUBLIC_UK_SEARCH_API_URL
  ? process.env.NEXT_PUBLIC_UK_SEARCH_API_URL
  : '${NEXT_PUBLIC_UK_SEARCH_API_URL}';

export const ukI15SearchApiURL: string = process.env.NEXT_PUBLIC_UK_I15_SEARCH_API_URL
  ? process.env.NEXT_PUBLIC_UK_I15_SEARCH_API_URL
  : '${NEXT_PUBLIC_UK_I15_SEARCH_API_URL}';

export const frI15SearchApiURL: string = process.env.NEXT_PUBLIC_FR_I15_SEARCH_API_URL
  ? process.env.NEXT_PUBLIC_FR_I15_SEARCH_API_URL
  : '${NEXT_PUBLIC_FR_I15_SEARCH_API_URL}';

export const deI15SearchApiURL: string = process.env.NEXT_PUBLIC_DE_I15_SEARCH_API_URL
  ? process.env.NEXT_PUBLIC_DE_I15_SEARCH_API_URL
  : '${NEXT_PUBLIC_DE_I15_SEARCH_API_URL}';

export const ukUserServiceApiURL: string = process.env.NEXT_PUBLIC_UK_USER_SERVICE_API_URL
  ? process.env.NEXT_PUBLIC_UK_USER_SERVICE_API_URL
  : '${NEXT_PUBLIC_UK_USER_SERVICE_API_URL}';

export const phraseWebAppProjectId: string = process.env.NEXT_PUBLIC_PHRASE_WEB_APP_PROJECT_ID
  ? process.env.NEXT_PUBLIC_PHRASE_WEB_APP_PROJECT_ID
  : '${NEXT_PUBLIC_PHRASE_WEB_APP_PROJECT_ID}';

export const loqateApiKey: string = process.env.NEXT_PUBLIC_LOQATE_API_KEY
  ? process.env.NEXT_PUBLIC_LOQATE_API_KEY
  : '${NEXT_PUBLIC_LOQATE_API_KEY}';

export const stripeApiKeyFR: string = process.env.NEXT_PUBLIC_STRIPE_CLIENT_FR
  ? process.env.NEXT_PUBLIC_STRIPE_CLIENT_FR
  : '${NEXT_PUBLIC_STRIPE_CLIENT_FR}';

export const stripeApiKeyUK: string = process.env.NEXT_PUBLIC_STRIPE_CLIENT_UK
  ? process.env.NEXT_PUBLIC_STRIPE_CLIENT_UK
  : '${NEXT_PUBLIC_STRIPE_CLIENT_UK}';

export const googleRecaptchaSiteKeyFR: string = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_FR
  ? process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_FR
  : '${NEXT_PUBLIC_RECAPTCHA_SITE_KEY_FR}';

export const googleRecaptchaSiteKeyUK: string = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_UK
  ? process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_UK
  : '${NEXT_PUBLIC_RECAPTCHA_SITE_KEY_UK}';
