import { userContextSchema } from './UserContextSchema';
import { UserContextEvent } from './UserContextEvent';
import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';

type Props = {
  userId?: string | null;
  values: Partial<LeadFormState>;
  isLoggedIn?: boolean;
};

export const userContextToEvent = ({
  userId,
  values: { firstName, lastName, email, phone, postcode, marketingOptIns },
  isLoggedIn,
}: Props): UserContextEvent => {
  const event: UserContextEvent = {
    schema: userContextSchema.ref,
    data: {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      postcode,
      opted_in: marketingOptIns,
      logged_in: isLoggedIn,
    },
  };

  return event;
};
