import { FC } from 'react';
import cn from 'classnames';

import { DefaultMapKeys, HeadingVariant, TypographyProps } from './Typography.types';

import styles from './Styles/Typography.module.css';
import { defaultVariantMapping } from './Typography.constants';

const findComponentFromVariant = (variant: DefaultMapKeys | HeadingVariant) => {
  if (typeof variant === 'string') {
    return defaultVariantMapping[variant];
  }

  return (
    defaultVariantMapping[variant.desktop] ||
    defaultVariantMapping[variant.mobile] ||
    defaultVariantMapping['body3']
  );
};

export const Typography: FC<TypographyProps> = ({
  variant = 'body3',
  className,
  Component = findComponentFromVariant(variant),
  children,
  dataTestId,
  ...restProps
}) => {
  let classNames;
  if (typeof variant === 'string') {
    classNames = cn(styles.typography, styles[variant], className);
  } else {
    const variantClassNames = Object.entries(variant).map(
      ([breakpoint, variantClass]) => styles[`${breakpoint}-${variantClass}`],
    );
    classNames = cn(styles.typography, ...variantClassNames, className);
  }
  return (
    <Component className={classNames} data-test-id={dataTestId} {...restProps}>
      {children}
    </Component>
  );
};

Typography.displayName = 'Typography';

export type { DefaultMapKeys };
